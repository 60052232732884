

.controles-protocolos{
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--gris);
  border-radius: 25px;
}

.wrap-control{
  width: 15%;
}
.container-btn-ctrl{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  cursor: pointer;
}
.container-btn-ctrl.l{
  justify-content: flex-start;
}
.container-btn-ctrl.r{
  justify-content: flex-end;
}
.btn-ctrl{
  background-color: var(--morado);
  color: white;
  border-radius: 12px;
  width: 100px;
  height: 30px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.protocolos{
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}


.btn-protocolo{
  color:var(--morado);
  padding: 5px 25px;
  cursor: pointer;
}


.btn-protocolo.active{
  //color:var(--morado);
  font-weight: 600;
  font-size: 16px;

  background: #6633994d;
  color: white;
  border-radius: 8px;
}

.contenido-paso{
  margin-top: 20px;
  background: #66339905;
  border-radius: 15px;
  padding: 20px;
}


.badge.tipo-respuesta{
  padding: 8px 15px;
  margin-left: 10px;
}


.btn-tab-step{
  float: left;
}

.group-steps {
  display: flex;
}

.container-options{
  margin-top: 18px;
}

.container-options .form-check-inline{
  margin-right: 2.5rem;
}

.container-step-validado{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.step-validado{
  background-color: var(--verde-success);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  border-radius: 8px;
  color: white;
  height: 25px;
}
.step-validado span{
  margin-left: 5px;
}





