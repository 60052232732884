

.container-reportes{

}

.item-reporte{
  display: block;
  color: black;
  border: 1px solid #f2f2f2;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 20px;
}

.item-reporte a{
  color: black;
  text-decoration: none !important;
}
.item-reporte a:hover{
  color: black;
}

