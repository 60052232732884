//NUEVO PROTOCOLOS
.container-paso{
  background-color: #f7f7f7;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 40px;
}
.container-paso .s-left{
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2px;
}
.container-paso .s-right{
  width: 90%;
}

.btn-sortable{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.btn-sortable div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 15px;
  height: 10px;
}
.btn-sortable div span{
  color: transparent;
  background-color: var(--morado-bajo);
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.btn-eliminar-paso{
  width: 35px;
  height: 35px;
  background-color: var(--rojo);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
}

.container-respuesta{
  border-radius: 15px;
  width: 100%;
  min-height: 50px;
  padding: 20px;
}


.container-paso .form-check-input:checked {
  background-color: var(--verde);
  border-color: var(--verde);
}
.form-switch .form-check-input {

}

.check-tiene-pregunta input{
  width: 3rem !important;
}
.check-tiene-pregunta label{
  margin-left: 10px !important;
}

.check-tiene-pregunta .form-switch{
  display: flex;
}


.btn-agregar-respuesta{
  background-color: #c4e3bd;
  color: #44b02b;
  padding: 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.btn-agregar-respuesta p{
  margin: 0px;
  font-weight: 600;

}


.icon-add-respuesta{
  color: #44b02b;
  font-size: 22px;
  margin-right: 5px;
  font-weight: 600;
}


.card-protocolo{
  min-height: 320px !important;
}

.tab-user{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}


.modal-editar-usuario .row,
.modal-editar-password .row,
{
  margin-bottom: 30px;
}

.modal-editar-usuario,
.modal-editar-password{
  width: 100%;
  padding: 30px;
  marginBottom: 100px
}

